<template>
	<form v-if="!sentSuccessfully" @submit.prevent="resetPassword">
		<div class="d-flex flex-wrap align-items-center w-100 justify-content-center mb-5">
			<div class="col-md-2 mb-3 mb-md-0 d-flex justify-content-center justify-content-md-start pl-md-0">
				<z-back-button class="float-left" @click.native="back" />
			</div>
			<div class="col-12 col-md-8">
				<h3 class="mb-0">
					Obnovenie hesla
				</h3>
			</div>
			<div class="col-md-2"></div>
		</div>

		<w-input v-model="$v.form.email" label="Zadajte svoju emailovú adresu" chevron />
		<b-button pill variant="success" class="z-submit-btn mt-3 float-right" :disabled="$v.$dirty && $v.$invalid" type="submit">
			Odoslať
		</b-button>
	</form>
	<div v-else class="text-center">
		Email s pokynmi na zmenu hesla bol odoslaný na <strong>{{ email }}</strong
		>.
	</div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
	components: {},

	data() {
		return {
			form: {
				email: ''
			},
			sentSuccessfully: false
		}
	},

	validations() {
		return {
			form: {
				email: { required, email }
			}
		}
	},

	methods: {
		async resetPassword() {
			this.$v.$touch()
			if (this.$v.$invalid) {
				this.$v.$touch()
				return
			}

			try {
				await this.$store.dispatch('wAuth/forgotPassword', { email: this.form.email })
				this.sentSuccessfully = true
			} catch (error) {
				this.$wToast.error(error)
			}
		},

		back() {
			this.$router.go(-1)
		}
	}
}
</script>
